import { SingleCommunityType } from '@xborglabs/ui-shared';

import { SingleCommunity } from './SingleCommunity';

const COMING_SOON_COMMUNITIES = [
  {
    name: 'Apeiron',
    slug: 'apeiron',
    description:
      'An exciting blend of Roguelite x RTS Card Game with a dash of Doodiness',
    logo: '/images/explore/apeiron-logo.png',
    width: 32,
    height: 32,
  },
  {
    name: 'AOF',
    slug: 'aof',
    logo: '/images/explore/aof-verse.png',
    width: 32,
    height: 32,
    description:
      'Immerse yourself in a world of games where you can earn coins, conquer battles, and collect unique digital treasures',
  },
  {
    name: 'Bushi',
    slug: 'bushi',
    logo: '/images/explore/bushi-logo.png',
    width: 24,
    height: 32,
    description:
      'A fast-paced & competitive third-person shooter built with Unreal Engine 5',
  },
  {
    name: 'Shrapnel',
    slug: 'shrapnel',
    logo: '/images/explore/shrapnel-logo.jpeg',
    width: 32,
    height: 32,
    description:
      'The 1st moddable extraction shooter game, built by an Award-winning team using UE5 & blockchain',
  },
];
export function ComingSoonCommunities({ limit }: { limit?: number }) {
  const communities =
    typeof limit === 'number'
      ? COMING_SOON_COMMUNITIES.slice(0, limit)
      : COMING_SOON_COMMUNITIES;

  return (
    <>
      {communities.map((c, i) => (
        <SingleCommunity
          key={i}
          community={
            {
              name: c.name,
              slug: c.slug,
              communityId: '',
              isMember: false,
              memberCount: 0,
              activeQuests: 0,
              completedQuests: 0,
              hasAvatar: false,
              gamerCardEnabled: false,
              content: {
                id: String(i),
                name: c.name,
                description: [{ children: [{ text: c.description }] }],
                images: {
                  logoImageUrl: c.logo,
                  coverImageUrl: '',
                },
                social: {
                  twitterUrl: '',
                  discordUrl: '',
                  websiteUrl: '',
                },
              },
            } satisfies SingleCommunityType
          }
          isMock
        />
      ))}
    </>
  );
}
