import { USER_SOCIAL_TYPE } from '@xborglabs/ui-shared';
import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import filter from 'lodash/filter';
import head from 'lodash/head';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useIntentToast } from '@/modules/landing/hooks/useIntentToast';
import { ReferralInput } from '@/modules/referrals';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { IconLink } from '@/modules/shared/components/Atom/Buttons/IconButton/IconLink';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { Progressbar } from '@/modules/shared/components/Atom/Progressbar';
import { API_ROUTES } from '@/routes';

import { PRIVATE_ROUTES } from '../../../../routes';
import classes from './LandingUserProgress.module.scss';

export const LandingUserProgress = () => {
  useIntentToast();
  const { t } = useTranslation(['landing']);
  const { data: authState } = useUserProfile();

  type SupportedSocialProviders = Exclude<
    USER_SOCIAL_TYPE,
    | USER_SOCIAL_TYPE.YOUTUBE
    | USER_SOCIAL_TYPE.EPIC
    | USER_SOCIAL_TYPE.FACEIT
    | USER_SOCIAL_TYPE.APEIRON
  >;

  const percentage = useMemo(() => {
    const socials = authState?.socials ?? [];
    const totalUserSocials = socials.length ?? 0;
    return Math.ceil(totalUserSocials * 16.66);
  }, [authState?.socials]);

  const nextProvider = useMemo(() => {
    const socials = authState?.socials ?? [];
    const socialAccounts = socials.map((s) => s.accountType);
    const excluded = [
      USER_SOCIAL_TYPE.DISCORD,
      USER_SOCIAL_TYPE.TWITTER,
      USER_SOCIAL_TYPE.TWITCH,
      USER_SOCIAL_TYPE.STEAM,
      USER_SOCIAL_TYPE.RIOT,
      USER_SOCIAL_TYPE.BATTLENET,
      // USER_SOCIAL_TYPE.YOUTUBE,
      // USER_SOCIAL_TYPE.EPIC,
    ].filter((type) => !socialAccounts.includes(type));

    const availableProviders = filter(
      excluded,
      (type) =>
        type !== USER_SOCIAL_TYPE.EPIC && type !== USER_SOCIAL_TYPE.YOUTUBE,
    ) as unknown as SupportedSocialProviders[];

    return head(availableProviders);
  }, [authState?.socials]);

  return (
    <Card className="flex column gap-large">
      <div className={classes.top_bar}>
        <Link
          className={classes.display_name}
          href={PRIVATE_ROUTES.PROFILE('manage-account')}
        >
          {authState?.displayName}
        </Link>
        <div className={classes.connected_socials}>
          {percentage !== 100 ? <UnconnectedSocialButton /> : <></>}
        </div>
      </div>

      {percentage !== 100 ? (
        <>
          <Progressbar progress={percentage} />
          <AddSocialPanel provider={nextProvider} />
        </>
      ) : (
        <div className="content refer-friend flex column">
          <div className="lexend-body-s">{t('landing:referFriends')}</div>
          <ReferralInput />
        </div>
      )}
    </Card>
  );
};

function UnconnectedSocialButton() {
  return (
    <IconLink
      variant="transparent"
      href={PRIVATE_ROUTES.PROFILE('manage-account')}
    >
      <Icon.plus size={16} color="white" />
    </IconLink>
  );
}

function AddSocialPanel({ provider }: { provider?: USER_SOCIAL_TYPE }) {
  const { t } = useTranslation(['landing']);
  const router = useRouter();
  const redirectTo = (platform: string) =>
    `?redirectTo=${encodeURIComponent(router.asPath)}&intent=${platform}`;

  if (!provider) {
    return null;
  }

  const IconComponent =
    Icon[provider.toLowerCase() as keyof typeof Icon] ?? null;
  const text = t(`landing:add${provider}`);
  const notConnectedText = t(`landing:${provider}NotConnected`);
  const connectLink =
    API_ROUTES[`${provider.toUpperCase()}_CONNECT` as keyof typeof API_ROUTES] +
    redirectTo(provider);
  if (!IconComponent || !connectLink) return null;

  return (
    <Card variant="overlay" className={classes.social_connect}>
      <div className={classes.social_connect_text}>
        <IconComponent size={32} color="white" />
        <div className="lexend-body-xs1">{notConnectedText}</div>
      </div>

      <div className={classes.social_connect_button}>
        <Button variant={BUTTON_VARIANT.TRANSPARENT} href={connectLink}>
          {text}
        </Button>
      </div>
    </Card>
  );
}
