import { m } from 'framer-motion';
import React, {
  ElementRef,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { IconButton } from '@/modules/shared/components/Atom/Buttons/IconButton/IconButton';

import classes from './Carousel.module.scss';
import { Icon } from '../../Atom/Icon';

export function Carousel(
  props: HTMLAttributes<HTMLDivElement> & {
    items: ReactNode[];
    renderHeading?: () => ReactNode;
    columns?: 1 | 2;
    gap?: number;
  },
) {
  const [currentPage, setCurrentPage] = useState(0);
  const { items, renderHeading, columns = 2, gap = 16 } = props;
  const [containerWidth, setContainerWidth] = useState(0);
  const containerWidthRef = useRef<ElementRef<'div'>>(null);
  const trackWidthRef = useRef(0);
  const totalDots = items.length;
  const shift =
    (containerWidth / columns) * currentPage + (currentPage !== 0 ? gap : 0);

  useEffect(() => {
    // create a listener on window resize to update container width on resize
    document.addEventListener('resize', () => {
      if (!containerWidthRef.current) return;
      setContainerWidth(
        containerWidthRef.current.getBoundingClientRect().width,
      );
    });

    return () => {
      document.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <div className={classes.carousel_container}>
      <div className={classes.carousel_header}>
        {renderHeading?.()}
        <Dots
          setCurrentPage={setCurrentPage}
          totalDots={totalDots}
          currentPage={currentPage}
        />
      </div>
      <div className={classes.carousel_item_container}>
        <IconButton
          className={classes.next}
          onClick={() => {
            setCurrentPage(() => {
              const next = currentPage + 1;
              if (next + 1 > totalDots) return 0;
              return next;
            });
          }}
        >
          <Icon.arrowRight size={16} color={'white'} />
        </IconButton>
        <IconButton
          className={`${classes.prev} ${
            currentPage === 0 ? classes.disabled : ''
          }`}
          onClick={() => {
            setCurrentPage(() => {
              const next = currentPage - 1;
              if (next < 0) return 0;
              return next;
            });
          }}
        >
          <Icon.arrowRight size={16} color={'white'} />
        </IconButton>
        <div
          className={classes.carousel_item_container_overflow}
          ref={(el) => {
            if (!el) return;
            setContainerWidth(el.getBoundingClientRect().width);

            return el;
          }}
        >
          <m.div
            className={classes.carousel_track}
            style={{
              gap: gap,
            }}
            initial={{ x: 0 }}
            animate={{ x: -shift }}
            ref={(el) => {
              if (!el) return;
              trackWidthRef.current = el.getBoundingClientRect().width;
            }}
          >
            {items.map((item) => (
              <>
                <div
                  className={classes[`carousel_item_wrapper_${columns}`]}
                  style={{
                    width: containerWidth / columns,
                  }}
                >
                  {item}
                </div>
              </>
            ))}
          </m.div>
        </div>
      </div>
    </div>
  );
}

function Dots({
  totalDots,
  currentPage,
  setCurrentPage,
}: {
  totalDots: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}) {
  return (
    <div className={classes.dots_container}>
      {Array.from({ length: totalDots }).map((_, i) => (
        <IconButton
          className={`${classes.dots} ${
            currentPage === i ? classes.active : undefined
          }`}
          key={i}
          onClick={() => setCurrentPage(i)}
        />
      ))}
    </div>
  );
}
