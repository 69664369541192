import classes from './LoggedInState.module.scss';
import { LandingCommunityStats } from '../LandingCommunityStats';
import { LandingGamerbaseApps } from '../LandingGamerbaseApps';
import { LandingHead } from '../LandingHead';
import { LandingUserProgress } from '../LandingUserProgress';

export function LoggedInState() {
  return (
    <>
      <div className={classes.head}>
        <LandingHead />
      </div>
      <div className={classes.container}>
        <LandingUserProgress />
        <LandingCommunityStats />
        <LandingGamerbaseApps />
      </div>
    </>
  );
}
