import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode } from 'react';

export function ShowOnFeatureFlag({
  flag,
  children,
}: {
  children: ReactNode;
  flag: string;
}) {
  const flags = useFlags();

  if (flags[flag]) {
    return <>{children}</>;
  }

  return null;
}
