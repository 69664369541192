import { useUserProfile } from '@xborglabs/ui-shared/dist/client';
import { useEffect } from 'react';

import { trackPage } from '@/lib/mixpanel';
import { GuestState } from '@/modules/landing/components/GuestState/GuestState';
import { LoggedInState } from '@/modules/landing/components/LoggedInState/LoggedInState';
import { Layout } from '@/modules/shared/components/Template/Containers';
import { useSetBackUrl } from '@/modules/shared/hooks/useSetBackUrl';
import { GLOBAL_ROUTES } from '@/routes';

import './_LandingContainer.scss';
export function LandingContainer() {
  useSetBackUrl();

  useEffect(() => {
    trackPage(GLOBAL_ROUTES.LANDING);
  }, []);
  const { data: authState } = useUserProfile();

  return (
    <Layout>
      <div className="landing-page-overflow">
        <div
          className={`${
            authState?.isLoggedIn ? 'landing-page page-spacer' : 'guest-page'
          }`}
        >
          {authState?.isLoggedIn ? <LoggedInState /> : <GuestState />}
        </div>
      </div>
    </Layout>
  );
}
