import { useTranslation } from 'next-i18next';

import { Videos } from '@/assets/videos';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { GLOBAL_ROUTES } from '@/routes';

import classes from './LandingHero.module.scss';
export const LandingHero = () => {
  const { t } = useTranslation(['landing', 'globals']);

  return (
    <div className={classes.hero_container}>
      <video
        src={Videos.RAIN}
        autoPlay
        loop
        muted
        className={classes.video__background}
      />

      <video
        src={Videos.HERO}
        autoPlay
        loop
        muted
        className={classes.video__hero}
      />

      <div className={classes.content}>
        <div className={classes.hero_content}>
          <h1 className={classes.hero_title}>{t('landing:title')}</h1>

          <p className={classes.hero_description}>{t('landing:description')}</p>

          <Button variant={BUTTON_VARIANT.RED} href={GLOBAL_ROUTES.EXPLORE}>
            {t('globals:explore')}
          </Button>
        </div>
      </div>
    </div>
  );
};
