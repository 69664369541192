import { Videos } from '@/assets/videos';
import {
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { MODAL } from '@/modules/shared/components/Template/Popups/types';
import { openModal } from '@/modules/shared/components/Template/Popups/utils/modal';

export const LandingPlayground = () => {
  return (
    <div className="landing__playground">
      <div className="container flex middle">
        <video src={Videos.AVATARS} autoPlay loop muted />

        <div className="content flex column top">
          <h3 className="integralfc-heading-lg">The Social MMO Playground</h3>
          <p className="lexend-heading-m">
            Step into your favorite gaming communities. Play epic games, connect
            with friends, and collect amazing rewards.
          </p>

          <Button
            variant={BUTTON_VARIANT.RED}
            onClick={() => openModal(MODAL.LOGIN, undefined, { login: true })}
            className="get-started"
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};
