import { useLiveAndUpcomingEvents } from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';

import { SingleEvent } from '@/modules/event';

export const LandingEvents = () => {
  const { t } = useTranslation(['landing']);
  const { data: events, isLoading } = useLiveAndUpcomingEvents();
  if (isLoading || !events?.events?.length) return null;

  return (
    <div className="landing__events">
      <div className="container">
        <h3 className="integralfc-heading-lg text-center">
          {t('landing:joinEvents')}
        </h3>

        <div className="list flex row">
          {events.events?.slice(0, 3)?.map((event) => (
            <div className="event-container" key={event.containerId}>
              <SingleEvent
                data={event}
                dateVisible={false}
                communityLogoVisible={true}
                participantsVisible={false}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
