import { useCommunities } from '@xborglabs/ui-shared/dist/client';
import first from 'lodash/first';
import { useTranslation } from 'next-i18next';

import { SingleCommunity } from '@/modules/community';
import { ComingSoonCommunities } from '@/modules/explore/components/ComingSoonCommunities';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Icon } from '@/modules/shared/components/Atom/Icon';

import classes from './LandingCommunities.module.scss';

export function LandingCommunities() {
  const { t } = useTranslation(['landing']);
  const { data } = useCommunities();
  const firstCommunity = first(data?.results ?? []);

  return (
    <div className={classes.landing_communities}>
      <div className={classes.content_wrapper}>
        <div className={classes.left}>
          <h3 className={classes.join_title}>{t('landing:joinCommunities')}</h3>

          <div className={classes.or}>{t('landing:or')}</div>

          <Button
            variant={BUTTON_VARIANT.TRANSPARENT}
            href="https://tally.so/r/w8LLEP"
            size={BUTTON_SIZE.LARGE}
            target="_blank"
            className={classes.create_community}
          >
            <Icon.plus size={12} color="white" />
            {t('landing:createCommunity')}
          </Button>
        </div>

        <div className={classes.right}>
          {typeof firstCommunity !== 'undefined' && (
            <SingleCommunity community={firstCommunity} />
          )}

          <ComingSoonCommunities limit={1} />
        </div>
      </div>
    </div>
  );
}
