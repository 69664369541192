import { shortenId } from '@xborglabs/ui-shared';
import {
  useComingUpEvent,
  useCommunityDetail,
} from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Skeleton from 'react-loading-skeleton';

import { Images } from '@/assets/imgs';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { Carousel } from '@/modules/shared/components/Molecule/Carousel/Carousel';
import { Parser } from '@/modules/shared/components/Organism/RichText';

import classes from './LandingGamerbaseApps.module.scss';

export function LandingGamerbaseApps() {
  const { t } = useTranslation(['landing']);

  return (
    <Card>
      <Carousel
        columns={1}
        renderHeading={() => (
          <div className="lexend-body-s font-bold ">{t('gamerbaseApps')}</div>
        )}
        items={[
          <StaticApp
            key={'my-bds'}
            bg={Images.landing.bds}
            ctaLabel={'Join waitlist'}
            url={'https://www.mybds.gg/'}
            logo={Images.landing.communities.bds}
            name={'My BDS'}
            description={t('bdsSlogan')}
          />,
          <StaticApp
            key={1}
            bg={Images.landing.dojo}
            ctaLabel={'Join The Dojo'}
            url={'https://dojo.nip.gl/'}
            logo={Images.landing.communities.nip}
            name={'NIP'}
            description={t('nipDojoSlogan')}
          />,
          // <ComingUpEvent key={2} />,
        ]}
      />
    </Card>
  );
}

function ComingUpEvent() {
  const { data, isLoading } = useComingUpEvent();
  const { data: community } = useCommunityDetail(data?.containerId);

  if (isLoading) {
    return (
      <Card className={classes.community}>
        <div className="coming-up-content expand flex between middle w-full">
          <div className="flex column top">
            <div className="lexend-body-m">
              <Skeleton />
            </div>
          </div>
        </div>
      </Card>
    );
  }

  if (!data || !community) return null;

  return (
    <Link
      href={`/c/${community.slug}/events/${shortenId(data.eventId)}`}
      className={classes.community}
    >
      <Image
        src={data.images?.coverImageUrl ?? Images.landing.tournament}
        alt=""
        fill
        className={classes.bg}
      />
      <div className={classes.community_content}>
        <div className={classes.community_text}>
          <div className="lexend-body-m">{data.title}</div>
          <p className={classes.description}>
            <Parser isPlain content={data.description} />
          </p>
        </div>
      </div>
    </Link>
  );
}

function StaticApp({
  bg,
  logo,
  url,
  ctaLabel,
  name,
  description,
}: {
  bg: string;
  logo: string;
  url: string;
  ctaLabel: string;
  name: string;
  description: string;
}) {
  return (
    <div className={classes.community} key={1}>
      <Image src={bg} alt="" className={classes.bg} fill />
      <div className={classes.community_content}>
        <div className="flex between middle w-full">
          <div className="flex middle">
            <Image
              src={logo}
              alt=""
              width={48}
              height={48}
              className={classes.static_logo}
            />
          </div>
          <Button
            variant={BUTTON_VARIANT.TRANSPARENT}
            href={url}
            target="_blank"
            size={BUTTON_SIZE.SMALL}
          >
            {ctaLabel}
          </Button>
        </div>
        <div className={classes.community_text}>
          <div className={classes.community_name}>{name}</div>
          <p className={classes.description}>{description}</p>
        </div>
      </div>
    </div>
  );
}
