import {
  LandingCommunities,
  LandingEvents,
  LandingFooter,
  LandingHero,
  LandingPlayground,
} from '@/modules/landing';

import classes from './GuestState.module.scss';

export function GuestState() {
  return (
    <div className={classes.guest_container}>
      <LandingHero />
      <LandingCommunities />
      <LandingEvents />
      <LandingPlayground />
      <LandingFooter />
    </div>
  );
}
