import { SingleCommunityType } from '@xborglabs/ui-shared';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { Images } from '@/assets/imgs';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { ShowOnFeatureFlag } from '@/modules/shared/components/Organism/FeatureFlagGate/ShowOnFeatureFlag';

import { GLOBAL_ROUTES } from '@/routes';
import { useRouter } from 'next/router';
import classes from './MyBase.module.scss';

export function MyBase({ community }: { community: SingleCommunityType }) {
  const { t } = useTranslation(['community']);
  const router = useRouter();
  return (
    <Card
      variant="outlined"
      className={classes.my_base}
      onClick={() => router.push(GLOBAL_ROUTES.COMMUNITY_SLUG(community.slug))}
    >
      <div>
        <Image
          src={
            community.content?.images.logoImageUrl ?? Images.communityview.logo
          }
          alt={`${community.name} logo`}
          width={32}
          height={32}
          className={classes.logo}
        />
        <p className={classes.logo_text}>{community.name}</p>
      </div>
      <div className={classes.stats}>
        <ShowOnFeatureFlag flag={'killSwitchQuestCounter'}>
          <StatBox title={t('quests')} value={community.activeQuests} />
        </ShowOnFeatureFlag>
        <StatBox title={t('members')} value={community.memberCount} />
      </div>
    </Card>
  );
}

function StatBox({ title, value }: { title?: string | null; value: number }) {
  return (
    <div className={classes.stat_box}>
      <p className={classes.title}>{title}</p>
      <p className={classes.value}>{value}</p>
    </div>
  );
}
