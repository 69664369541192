import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Images } from '@/assets/imgs';
import { GLOBAL_ROUTES } from '@/routes';

export const LandingFooter = () => {
  const { t } = useTranslation(['landing']);

  return (
    <div className="landing__footer">
      <div className="container flex column">
        <div className="content flex between middle">
          <Image
            src={Images.logo}
            width={24}
            height={24}
            alt=""
            className="footer__logo"
          />

          <div className="flex middle links lexend-body-s">
            <Link href={GLOBAL_ROUTES.FAQ}>FAQ</Link>
            <Link href={GLOBAL_ROUTES.CHANGELOG}>Changelog</Link>
            <Link
              href="https://hitmarker.net/jobs/xborg-senior-back-end-engineer-2350472"
              target="_blank"
            >
              We are hiring
            </Link>
            <Link href={GLOBAL_ROUTES.TERMS_AND_CONDITIONS}>
              Terms & conditions
            </Link>
            <Link href={GLOBAL_ROUTES.PRIVACY_POLICY}>Privacy policy</Link>
          </div>
        </div>

        <div className="disclaimer lexend-body-s">
          GamerBase is not endorsed by Riot Games, Valve Corporation, Epic
          Games, or Blizzard Entertainment and does not reflect the views or
          opinions of these companies or anyone officially involved in producing
          or managing their properties. Riot Games, Valve Corporation, Epic
          Games, Blizzard Entertainment, and all associated properties are
          trademarks or registered trademarks of their respective companies.
        </div>
      </div>
    </div>
  );
};
