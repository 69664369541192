import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SiteActions } from '@/redux/site/actions';

export function useSetBackUrl(auto: boolean = true) {
  const router = useRouter();
  const dispatch = useDispatch();
  const setBackUrl = useCallback(
    (url: string) => dispatch(SiteActions.setBackUrl(url)),
    [dispatch],
  );

  useEffect(() => {
    if (auto) {
      setBackUrl(router.asPath);
    }
  }, [auto, dispatch, router.asPath, setBackUrl]);

  return {
    setBackUrl,
  };
}
