import { LinkProps } from 'next/link';
import { AnchorHTMLAttributes } from 'react';

import classes from './IconButton.module.scss';

export type IconLinkProps = {
  variant?: 'solid' | 'transparent';
} & AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkProps;

export function IconLink({
  className,
  variant = 'solid',
  ...rest
}: IconLinkProps) {
  return (
    <a
      className={`${classes.icon_button} ${classes[variant]} ${className}`}
      {...rest}
    />
  );
}
