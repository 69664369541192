import { useJoinedCommunities } from '@xborglabs/ui-shared/dist/client';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { Icon } from '@/modules/shared/components/Atom/Icon';
import { Carousel } from '@/modules/shared/components/Molecule/Carousel/Carousel';
import { GLOBAL_ROUTES } from '@/routes';

import { MyBase } from '../MyBase/MyBase';

export const LandingCommunityStats = () => {
  const { t } = useTranslation(['landing']);

  const { data: communities } = useJoinedCommunities();

  return (
    <Card className="landing-community-stats landing-column-content">
      <div className="content flex column gap-normal">
        {typeof communities?.results.length === 'number' &&
        communities?.results.length > 0 ? (
          <Carousel
            renderHeading={() => (
              <div className="lexend-body-s font-bold ">
                {t('landing:communityStats')}
              </div>
            )}
            items={communities?.results.map((item) => (
              <MyBase community={item} key={item.communityId} />
            ))}
          />
        ) : (
          <div className="empty-community">
            <div className="flex column middle gap-small">
              <Icon.compass size={32} />
              <h2 className="empty-community__heading">
                {t('landing:noCommunitiesJoinedYet')}
              </h2>
            </div>
            <Link
              className="btn btn-transparent w-full text-center font-bold explore-button"
              href={GLOBAL_ROUTES.EXPLORE}
            >
              {t('landing:explore')}
            </Link>
          </div>
        )}
      </div>
    </Card>
  );
};
