import { ParsedUrlQuery } from 'querystring';

import { GetServerSidePropsContext, PreviewData } from 'next';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';

import { getServerSidei18nProps } from '@/lib/serverSideProps';
import { LandingContainer } from '@/modules/landing/components/LandingContainer';
import { dehydrateState } from '@/modules/landing/ssr';

export default function LandingPage() {
  return (
    <>
      <SEO />
      <LandingContainer />
    </>
  );
}

export async function getServerSideProps(
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
) {
  const i18nProps = await getServerSidei18nProps(context);

  const dehydratedState = await dehydrateState(context);

  return {
    props: {
      ...(i18nProps as { props: typeof i18nProps }).props, // TODO: this needs to change once all pages are using SSR
      dehydratedState,
    },
  };
}

function SEO() {
  const { t } = useTranslation(['landing']);
  return (
    <NextSeo
      title={t('landing:pageTitle')!}
      description={t('landing:pageDesc')!}
      openGraph={{
        title: t('landing:pageTitle')!,
        description: t('landing:pageDesc')!,
        images: [
          {
            url: `/images/og/landing.jpg`,
          },
        ],
      }}
    />
  );
}
