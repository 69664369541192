import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { Images } from '@/assets/imgs';
import AutoplayVideo from '@/modules/shared/components/Atom/AutoplayVideo/AutoplayVideo';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from '@/modules/shared/components/Atom/Buttons';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { GLOBAL_ROUTES } from '@/routes';
import animationClasses from '@/styles/animations/marquee.module.scss';

import classes from './LandingHead.module.scss';
export const LandingHead = () => {
  const { t } = useTranslation(['landing', 'globals']);
  return (
    <Card spacing="none" className={classes.landing_head}>
      <AutoplayVideo
        src="/videos/feed.mp4"
        videoType="video/mp4"
        className={classes.video_bg}
      />
      <MarqueeText />
      <div className={classes.content}>
        <div className={classes.content_title}>
          <Image src={Images.logo} alt="Logo" width={32} height={32} />
          Gamerbase
        </div>
        <div className="lexend-body-m">{t('landing:slideDescription')}</div>
        <Button
          href={GLOBAL_ROUTES.EXPLORE}
          className={classes.swiper}
          size={BUTTON_SIZE.LARGE}
          variant={BUTTON_VARIANT.RED}
        >
          {t('globals:explore')}
        </Button>
      </div>
    </Card>
  );
};

function MarqueeText() {
  const items = Array.from(Array(20));
  return (
    <div className={classes.epic_adventure}>
      {items.map((_, k) => (
        <span
          key={k}
          className={`flex ${animationClasses['animate-marquee']} ${
            k % 2 ? classes.alternate : ''
          }`}
        >
          An epic adventure awaits /
        </span>
      ))}
    </div>
  );
}
